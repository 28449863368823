import { TokenError } from '../exceptions/TokenError';
import { CardConfig, IGatewayConfig, IProvider } from '../types';
import { injectScript } from '../common';

export class Iugu implements IProvider {
  private static scriptUrl = 'https://js.iugu.com/v2';

  constructor(
    private options: IGatewayConfig[],
    private isTest?: boolean
  ) { }

  public async generate(config: CardConfig) {
    const [month, year] = config.expiration.split('/');

    const accountId = this.options.find((c) => c.name === 'account_id')?.value;

    try {
      await injectScript(Iugu.scriptUrl);
      const win = window as any;

      const token = await new Promise<string>((resolve, reject) => {
        const holderName = config.holder.split(' ');
        win.Iugu.setAccountID(accountId);
        win.Iugu.setTestMode(this.isTest ?? false);

        win.Iugu.createPaymentToken(
          {
            number: config.number,
            verification_value: config.cvv,
            first_name: holderName[0],
            last_name: holderName[1] ?? '',
            month,
            year,
          },
          (data: any) => {
            if (data.errors) {
              reject(new TokenError(data.errors));
            }

            resolve(data.id);
          }
        );
      });

      return token;
    } catch (err) {
      throw new TokenError('Failed to generate token');
    }
  }
}
