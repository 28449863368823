import axios from 'axios';

import { TokenError } from '../exceptions/TokenError';
import { CardConfig, IGatewayConfig, IProvider } from '../types';

export class PagarMeV5 implements IProvider {
  private static apiUrl = 'https://api.pagar.me/core/v5';

  constructor(
    private options: IGatewayConfig[],
    private isTest?: boolean
  ) {}

  public async generate(config: CardConfig) {
    const [month, year] = config.expiration.split('/');

    const api_key = this.options.find((c) => c.name === 'api_key')?.value;

    try {
      const result = await axios.post(`${PagarMeV5.apiUrl}/tokens?appId=${api_key}`, {
        type: 'card',
        card: {
          number: config.number,
          holder_name: config.holder,
          holder_document: config.document,
          exp_month: month,
          exp_year: year,
          cvv: config.cvv,
        },
      });

      const token = result.data.id;

      return token;
    } catch (err) {
      throw new TokenError('Failed to generate token');
    }
  }
}
