import axios from 'axios';

import { config } from './config';
import { PagarMeV5 as PagarMeV5 } from './providers/PagarMeV5';
import { CardConfig, IProvider } from './types';
import { Iugu } from './providers/Iugu';

type IOptions = {
  key: string;
  card: CardConfig;
};

type ProviderType = 'pagarme' | 'iugu';

type ProviderConfig = {
  gatewayConfigId: string;
  provider: ProviderType;
  isTest: boolean;
  config: any;
};

const providerMap = {
  pagarme: (options: any, isTest?: boolean) => new PagarMeV5(options, isTest),
  iugu: (options: any, isTest?: boolean) => new Iugu(options, isTest),
} as {
  [key: string]: (options: any, isTest?: boolean) => IProvider;
};

class RouterFy {
  public async generateToken(options: IOptions) {
    const appData = await this.loadTokenShape(options.key);
    const promises = appData
      .map(async (app) => {
        const provider = providerMap[app.provider]!(app.config, app.isTest);
        if (!provider) throw new Error('Provider not found');

        const token = await provider.generate(options.card);
        return {
          gatewayConfigId: app.gatewayConfigId,
          provider: app.provider,
          token,
        };
      });

    try {
      const result = await Promise.race([
        Promise.allSettled(promises),
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error('Timeout')), 20_000)
        ),
      ]) as any;

      const tokens = result
        .filter((r: any) => r.status === 'fulfilled')
        .map(
          (r: any) =>
            (r as any).value as {
              provider: ProviderType;
              token: string;
              gatewayConfigId: string;
            }
        );

      if (!tokens.length) {
        throw new Error('Error to generate token');
      }

      // todo: change
      return btoa(JSON.stringify(tokens));
    } catch (err) {
      console.log('Error generating token', err);
      throw err;
    }
  }

  private async loadTokenShape(key: string): Promise<ProviderConfig[]> {
    try {
      const response = await axios.get<ProviderConfig[]>(`${config.API_ENDPOINT}/config/${key}`);
      return response.data;
    } catch (err: any) {
      console.log('Error loading token shape', err?.response?.data);
      // fallback?
      throw err;
    }
  }
}

export const routerFy = new RouterFy();
