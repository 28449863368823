const INJECTED_SCRIPTS = new Map<string, Promise<unknown>>();

export const injectScript = (src: string) => {
  if (INJECTED_SCRIPTS.has(src)) {
    return INJECTED_SCRIPTS.get(src);
  }

  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  document.body.appendChild(script);

  const promise = new Promise((resolve, reject) => {
    script.onload = resolve;
    script.onerror = reject;
  });

  INJECTED_SCRIPTS.set(src, promise);

  return promise;
};

export const deinjectScript = async (src: string) => {
  if (!INJECTED_SCRIPTS.has(src)) {
    return;
  }

  const script = document.querySelector(`script[src="${src}"]`);
  if (script) {
    script.remove();
  }

  INJECTED_SCRIPTS.delete(src);
};